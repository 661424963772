import React from "react"
import { Bleed, Divider, Box } from "@shopify/polaris"

const CardDivider = () => (
    <Bleed marginInline="400">
        <Box paddingBlockStart="400">
            <Divider borderColor="border"/>
        </Box>
    </Bleed>
)

export default CardDivider
